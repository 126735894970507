













































import { isAdmin } from '@/api/auth';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import { IUser } from '@/models/user';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import router from '../router';
import store from '../store';
import { IAuthState } from '../store/modules/auth';
import { IAuthTranslations } from '../translations';
// @ is an alias to /src
@Component({
  name: 'UserProfile',
  components: { Input },
})
class UserProfile extends Vue {
  @Action('loginUser') public login!: (user: IUser) => void;

  @Getter('userAuthState') public userAuth!: IAuthState;

  @Getter('getAuthTranslations') public translations!: IAuthTranslations;

  @Mutation('setUserLogout') public logout!: () => void;

  public email = '';
  public password = '';
  public inputEmail!: IInput;
  public inputPassword!: IInput;
  public created() {
    if (this.userAuth.userLoggedIn) {
      router.push('/');
    }
    this.inputEmail = {
      id: 'user-email',
      label: this.translations.email,
      type: 'text',
      icon: 'mail_outline',
      placeholder: this.translations.email_placeholder,
      value: '',
    };

    this.inputPassword = {
      id: 'user-password',
      label: this.translations.password,
      type: 'password',
      icon: 'lock_open',
      placeholder: this.translations.password_placeholder,
      value: '',
    };
    this.logout();
  }
  public loginUser() {
    const { email, password } = this;

    this.login({
      email,
      password,
    });

    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setUserLogin') {
        isAdmin()
          .then(() => {
            const currentUser = mutation.payload;
            const localStorageProp =
              process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

            localStorage.setItem(
              localStorageProp,
              JSON.stringify({
                auth: mutation.payload,
                userLoggedIn: true,
              })
            );
            router.push('/');
          })
          .catch(() => {
            window.location.href = process.env.VUE_APP_REDIRECT_URL;
          });

        unsubscribe();
      }
    });
  }
}

export default UserProfile;
